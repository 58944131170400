import guards from './guards';

const Layout = () => import(/* webpackChunkName: "layout" */'../layouts/MainLayout');
const Home = () => import(/* webpackChunkName: "home" */'../views/Home');
const About = () => import(/* webpackChunkName: "home" */'../views/About');
const Contact = () => import(/* webpackChunkName: "home" */'../views/Contact');
const KnowledgeHub = () => import(/* webpackChunkName: "home" */'../views/Knowledge-hub');
const TrainingVideos = () => import(/* webpackChunkName: "home" */'../views/Training');
const Packages = () => import(/* webpackChunkName: "home" */'../views/Packages');
const Service = () => import(/* webpackChunkName: "home" */'../views/Service');
const ClientService = () => import(/* webpackChunkName: "home" */'../views/InnerPages/ClientService');
const SuppliersService = () => import(/* webpackChunkName: "home" */'../views/InnerPages/SupplierService');
const ConsultantService = () => import(/* webpackChunkName: "home" */'../views/InnerPages/ConsultantService');
const ContractManagersService = () => import(/* webpackChunkName: "home" */'../views/InnerPages/ContractManagersService');
const ContractorsService = () => import(/* webpackChunkName: "home" */'../views/InnerPages/ContractorService');

export default {
    path: '/',
    name: 'DashboardLayout',
    redirect: '/app/',
    component: Layout,
    beforeEnter: guards.loggedInGuard,
    children: [
        {
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/about/',
            name: 'About',
            component: About
        },
        {
            path: '/contact/',
            name: 'Contact',
            component: Contact
        },
        {
            path: '/service/',
            name: 'Service',
            component: Service
        },
        {
            path: '/knowledge-hub/',
            name: 'KnowledgeHub',
            component: KnowledgeHub
        },
        {
            path: '/training-videos/',
            name: 'TrainingVideos',
            component: TrainingVideos
        },
        {
            path: '/packages/',
            name: 'Packages',
            component: Packages
        },
        {
            path: '/Client-Service-Detials/',
            props: { default: true, prev: '/', next: '/' },
            name: 'Client-Service-Detials',
            component: ClientService
        },
        {
            path: '/Suppliers-Service-Detials/',
            name: 'Suppliers-Service-Detials',
            component: SuppliersService
        },
        {
            path: '/Consultant-Service-Detials/',
            name: 'Consultant-Service-Detials',
            component: ConsultantService
        },
        {
            path: '/ContractManagers-Service-Detials/',
            name: 'ContractManagers-Service-Detials',
            component: ContractManagersService
        },
        {
            path: '/Contractors-Service-Detials/',
            name: 'Contractors-Service-Detials',
            component: ContractorsService
        }
    ]
};
