const prod = process.env.NODE_ENV === 'production';

let baseUrl;
if (prod) {
    baseUrl = 'https://contractace.com/';
} else {
    baseUrl = 'https://contractaceuat.xeoscript.com/';
}

export default {
    auth: {
        login: baseUrl + 'auth/login/',
        status: baseUrl + 'auth/status/'
    },
    admin: {
        KnowledgeHub: {
            List: baseUrl + 'website/faq/list/'
        },
        TrainingVideo: {
            List: baseUrl + 'website/video/list/'
        },
        ContactUs: {
            email: baseUrl + 'website/enquiry/email/send/'
        }
    }
};
